@import 'susy';
@import 'variables';
@import 'utilities';

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: 16px;
	font-family: $font-stack-default;
	color: $white;
}

body {
	padding: 0;
	margin: 0;
}

h1 {
	font-size: 1.6em;
}

h2 {
	font-size: 1.4em;
}

a,
a:visited,
a:active {
	color: $white;
	text-decoration: none;
}

.container {
	width: 100%;
	max-width: 1920px;
	padding: 0 $spacing-default;
}

.row {
	@include susy-clearfix;
	clear: both;

	& > a {
		@include susy-clearfix;
		display: block;
	}
}

.module {
	display: block;
	background-color: $light-gray;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	min-height: 150px;
	padding: $spacing-default;
	float: left;
	margin-bottom: $spacing-default;
	width: 100%;
	@include shadow-default;

	&.full {

	}

	&.col-2-up {
		width: calc(50% - (#{$spacing-default} / 2));
		margin-right: $spacing-default;

		&:last-child {
			margin-right: 0;
		}
	}

	.flag {
		background-color: $red;
		color: $white;
		padding: 7px 16px;
		position: absolute;
		left: -$spacing-default;
		top: 20px;
		text-align: center;

		h2 {
			margin: 0;
			padding: 0;
			font-family: $font-stack-narrow;
			font-weight: 500;
			font-size: 1.8em;
			letter-spacing: 1px;
			color: $white;
			text-transform: uppercase;
		}
	}

	.caption-wrapper {
		background-color: $blue;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: $spacing-default;
		font-size: .8em;
		text-align: center;
		min-height: 50px;

		.caption {

		}
	}

	.img-responsive {
		object-fit: cover;
	}
}

header.module {
	text-align: center;
	padding-left: 12%;
	padding-right: 12%;
	display: flex;
	justify-content: center;
	align-items: center;
	float: none;

	h1 {
		margin: 0;
	}

	h1 + p {
		margin-top: .3rem;
	}
}

.module.flush {
	padding: 0;
}

.module.intro {
	overflow: hidden;
	min-height: 280px;
	position: relative;
	background-color: $light-gray;

	.img-header {
		position: absolute;
		bottom: 50px;
		left: 0;
		width: 100%;
		padding: 10px 10px 10px 33%;
		background-color: $blue-overlay;
		background: linear-gradient(to bottom, rgba(42, 95, 200, 0.9) 0%, rgba(28, 54, 134, 0.9) 100%);

		.featured-img {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 33%;
		}

		h1:first-of-type {
			margin-top: 0;
			margin-bottom: 0;
			//font-size: 1.6em;
		}

		p {
			font-size: .9em;
			line-height: 135%;
			margin-top: 5px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}

.flat {
	background-color: $light-gray;

	.module {
		box-shadow: none;
	}
}

a.social-media {
	display: flex;
	align-items: center;
	background-color: $white;
	min-height: 0;

	.content-wrapper {
		width: 100px;
		padding: $spacing-default;
		flex-grow: 1;
	}

	.icon {
		padding: 0;
		flex-grow: 0;
		width: 33%;
	}

	h3 {
		margin: 0;
	}

	p {
		margin: .3em 0 0;
	}

	&.facebook {
		color: $facebook-blue;
	}

	&.twitter {
		color: $twitter-blue;
	}

	&.pinterest {
		color: $pinterest-red;
	}

	&.instagram {
		color: $instagram-blue;
	}

	&.youtube {
		color: $youtube-red;
	}
}

.timeline {
	min-height: 0;

	.caption-wrapper {
		background-color: $green;
	}

	.year {
		flex-shrink: 0;
		font-size: 2.8em;
		margin-right: .5em;
	}

	.caption {
		flex-grow: 1;
		text-align: left;
		text-transform: uppercase;
		font-family: $font-stack-narrow;
		font-size: 1.2rem;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 100%;
	}
}

.product-feature {
	background-color: $green;

	h3 {
		text-align: center;
		margin: ($spacing-default * 2) 0 0;
	}
}

.nutrition-facts {
	background-color: $white;
	padding: $spacing-default * 3;

	img {
		max-width: 200px;
		margin: 0 auto;
	}
}

.where-to-find .module {
	margin: 0;
}

.where-to-find .hanging-sign {
	@include shadow-default;
	background-color: darken($tan, 10%);
	color: $tan;
	height: 123px;
	min-height: 0;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left top;
	padding-left: 130px;
	margin : $hanging-sign-spacing auto auto auto;
	position: relative;
	display: flex;
	align-items: center;
	width: 355px;
	float: none;

	.lead-in {
		text-transform: uppercase;
		font-size: 1em;
		margin-bottom: .5em;
	}

	.location {
		font-size: 1.5em;
	}

	&:before,
	&:after {
		content: '';
		background-image: url(../img/where2find/where2find_chains.png);
		background-position: 50% 0;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: -($hanging-sign-spacing);
		width: 20px;
		height: $hanging-sign-spacing;
	}

	&:before {
		left: $hanging-sign-chain-offset;
	}

	&:after {
		right: $hanging-sign-chain-offset;
	}
}

.row:last-of-type .hanging-sign {
	margin-bottom: $spacing-default;
}