// Font stacks
// ----------------------------------------------------------------------------

$font-stack-default: "Archer SSm A", "Archer SSm B", sans-serif;
$font-stack-narrow: "Tungsten A", "Tungsten B", sans-serif;


// Layout
// ----------------------------------------------------------------------------

$spacing-default: 10px;
$hanging-sign-spacing: $spacing-default * 5;
$hanging-sign-chain-offset: 30px;


// Colors
// ----------------------------------------------------------------------------

$blue:         #3564c4;
$blue-overlay: rgba(28, 58, 145, 0.9);
$white:        #fff;
$light-gray:   #e1e1e1;
$black:        #231f20;
$red:          #de0009;
$green:        #10ab10;
$tan:          #f0d9bf;

$facebook-blue:  #3c4e90;
$twitter-blue:   #67aee1;
$pinterest-red:  #cf1917;
$instagram-blue: #295086;
$youtube-red:    #dd1214;


// Effects
// ----------------------------------------------------------------------------

@mixin shadow-default() {
	box-shadow: 2px 2px 0.5px 0 rgba(147, 149, 152, .9);
}